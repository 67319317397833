
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { deepCopy } from '@/utils/general'
  import { forwarderColors } from '@/utils/forwarderColors'
  import { fixColor } from '@/utils/dashboards'

@Component({})
  export default class LeadByExecutive extends BaseChart {
  @Prop() leads: Record<string, any>
  @Prop() executives
  executivesAttribute = []
  forwardedSeries: { name: string; group: string; data: any[]; color: string }[] = []
  forwarderColors = forwarderColors

  // Methods
  getCountByExecutive (executive) {
    const leads = this.leads?.records || this.leads
    if (!leads) return
    if (executive !== 'SIN EJECUTIVO') {
      return leads.filter(lead => {
        return `${lead.executive?.person?.firstName} ${lead.executive?.person?.surname}` === executive
      }).length
    } else {
      return leads.filter(lead => !lead.executive).length
    }
  }

  async getExecutives () {
    const executives = await this.executives?.executivesForReports
    if (!executives) return
    this.executivesAttribute = executives.map(executive =>
      executive.firstName + ' ' + executive.surname
    )
  }

  getCountByPipeline (pipeline) {
    const leads = this.leads?.records || this.leads
    if (!leads) return
    return leads.filter(lead => {
      return lead?.pipeline?.name === pipeline?.name
    }).length
  }

  async getData () {
    if (!this.leads) return
    const leads = this.leads?.records || this.leads
    if (!leads) return
    const executives = [...new Set(leads.map(lead => {
      if (!lead.executive) return
      return `${lead.executive?.person?.firstName} ${lead.executive?.person?.surname}`
    }))].filter(executive => executive !== undefined).sort()
    if (leads.filter(lead => !lead.executive).length > 0) {
      executives.push('SIN EJECUTIVO')
    }

    await this.getExecutives()

    const pipelines = this.pipelines

    const dataByPipeline = pipelines.map(pipeline => {
      const data = []

      executives.forEach(executive => {
        let filteredByExecutive = []
        if (executive !== 'SIN EJECUTIVO') {
          filteredByExecutive = leads.filter(lead => {
            if (!lead.executive) return
            const executiveName = `${lead.executive?.person?.firstName} ${lead.executive?.person?.surname}`
            return executiveName === executive && lead.pipeline?.name === pipeline?.name
          })
        } else {
          filteredByExecutive = leads.filter(lead => !lead.executive && lead.pipeline?.name === pipeline?.name)
        }

        data.push(filteredByExecutive.length)
      })
      return {
        name: pipeline?.description,
        group: 'leads',
        data,
        color: fixColor(pipeline?.color),
      }
    })

    const dataByForwarder = {
      name: 'Derivado',
      group: 'forwarded',
      data: [],
      color: '#BEAEED',
    }

    const dataByProspected = {
      name: 'Prospectado',
      group: 'forwarded',
      data: [],
      color: '#EFDE72',
    }

    executives.forEach(executive => {
      let filteredByExecutive = []
      if (executive !== 'SIN EJECUTIVO') {
        filteredByExecutive = leads.filter(lead => {
          if (!lead.executive) return
          const executiveName = `${lead.executive?.person?.firstName} ${lead.executive?.person?.surname}`
          return executiveName === executive
        })
      } else {
        filteredByExecutive = leads.filter(lead => !lead.executive)
      }

      const prospected = filteredByExecutive.filter(lead => {
        return lead.createdBy?.['x-hasura-user-email'] === lead.executive?.person?.user?.email &&
          !lead.idForwarder
      })
      const genioForwarded = filteredByExecutive.filter(lead => {
        return lead.createdBy?.['x-hasura-user'] === 'system' &&
          !lead.idForwarder
      })
      const forwarded = filteredByExecutive.filter(lead => lead.idForwarder)

      dataByForwarder.data.push(genioForwarded.length + forwarded.length)
      dataByProspected.data.push(prospected.length)
    })

    this.series = dataByPipeline.filter(pipeline => pipeline.data.some(data => data !== 0))
    this.forwardedSeries = [dataByForwarder, dataByProspected]

    let maxSum = 0
    this.series
      // .slice(this.series.length - 2, this.series.length - 1)
      .map(serie => serie.data)
      .map(data => deepCopy(data).sort((a, b) => b - a))
      .forEach(data => maxSum += data[0])

    if (maxSum < 4) maxSum += 2
    if (maxSum < 8) maxSum += 4
    else if (maxSum < 16) maxSum += 8
    else maxSum += 16

    this.chartOptions = {
      chart: {
        height: 400,
        stacked: true,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              formatter: val => `${val} / ${Math.round(((val / this.leadCount) * 100))}%`,
              offsetX: 16,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val, opt) => {
          return opt.w.globals.series[opt.seriesIndex][opt.dataPointIndex]
        },
      },
      tooltip: {
        y: {
          formatter (val) {
            return val
          },
        },
      },
      fill: {
        opacity: 1,
      },
      xaxis: {
        categories: executives.map(executive => `${(executive as string).split(' ')[0]} ${(executive as string).split(' ')[1].slice(0, 1)}.: ${this.getCountByExecutive(executive)}${this.executivesAttribute.includes(executive) ? `/${Math.round(((this.getCountByExecutive(executive) / this.executiveLeadsCount)) * 100)}%` : ''}`),
        min: 0,
        max: maxSum,
      },
    }
  }

  // Getters
  get pipelines () {
    const leads = this.leads?.records || this.leads
    const pipelines: { name: string, description: string, color: string }[] = []
    if (leads) {
      leads.forEach(lead => {
        if (!pipelines.map(pipeline => pipeline?.name).includes(lead.pipeline?.name)) {
          pipelines.push(lead.pipeline)
        }
      })
    }
    return pipelines
  }

  get executiveLeadsCount () {
    const leads = this.leads?.records || this.leads
    if (leads) {
      return leads
        .filter(lead => {
          const executive = `${lead.executive?.person?.firstName} ${lead.executive?.person?.surname}`
          return this.executivesAttribute.includes(executive)
        })
        .length
    } else return 0
  }

  get forwardedCount () {
    const leads = this.leads?.records || this.leads
    if (!leads) return
    return leads.filter(lead => lead.idForwarder).length +
      leads.filter(lead => lead.createdBy?.['x-hasura-user'] === 'system' &&
        !lead.idForwarder).length
  }

  get prospectedCount () {
    const leads = this.leads?.records || this.leads
    if (!leads) return
    return leads.filter(lead =>
      lead.createdBy?.['x-hasura-user-email'] === lead.executive?.person?.user?.email &&
      !lead.idForwarder
    ).length
  }

  get leadCount () {
    const leads = this.leads?.records || this.leads
    if (!leads) return
    return leads.length
  }

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  onDatesChange () {
    this.getData()
  }
  }
